import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Counting Valleys – HackerRank Challenge",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-15T07:02:47.000Z",
  "url": "/2019/05/counting-valleys-hackerrank-challenge/",
  "categories": ["Exercises"],
  "tags": ["challenges", "HackerRank"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The Problem: We have a traveler that travelers that counts his steps Up and Downs. He now wants to know how many valleys he passed through. Valley is one or more step below sea level and back to sea level.`}</h2>
    <a href="https://github.com/tzookb/programming-challenges/blob/master/hackerrank/cracking_interview/counting-valleys/readme.md" target="_blank" rel="noreferrer noopener" aria-label="My solution on Github (opens in a new tab)">My solution on Github</a> [HackerRank challenge][1] The Code: 
    <pre><code parentName="pre" {...{}}>{`function countingValleys(n, s) {
    //dont care about "n"
    let level = 0;
    let valleys = 0;
    let isNewVally = false;

    s.split('').map(step =&gt; {
        level = step === 'U' ? level + 1 : level -1;
        if (level === -1 &amp;&amp; !isNewVally) {
            isNewVally = true;
        }
        if (level === 0 &amp;&amp; isNewVally) {
            isNewVally = false;
            valleys++;
        }
    })
    return valleys;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      